<template>
  <div id="forget-pass" class="forget-pass">
    <div class="progress">
      <el-steps :active="step" align-center>
        <el-step title="验证身份"></el-step>
        <el-step title="设置密码"></el-step>
        <el-step title="修改成功"></el-step>
      </el-steps>
    </div>
    <div class="forget-pass-main">
      <div v-if="step===1||step===2">
        <div class="header">
          <div class="title"><span>{{ title }}</span></div>
        </div>
        <div class="body">
          <el-form
              :model="forgetForm"
              :rules="forgetRules"
              status-icon
              ref="forgetForm"
              label-width="120px"
              label-suffix="：">
            <div class="input-items">
              <div v-if="step===1" class="step1">
                <el-form-item prop="phonenumber" label="手机号码">
                  <el-input placeholder="请输入联系人手机号码" v-model="forgetForm.phonenumber"></el-input>
                </el-form-item>
                <el-form-item prop="imgCode" label="图形验证码">
                  <el-input placeholder="请输入图形验证码" v-model="forgetForm.imgCode" style="width: 73%"></el-input>
                  <div class="reg-code">
                    <img :src="codeUrl" @click="getCode" class="reg-code-img"/>
                  </div>
                </el-form-item>
                <el-form-item prop="code" label="短信验证码">
                  <el-input placeholder="请输入短信验证码" v-model="forgetForm.code"
                            style="width: 73%"></el-input>
                  <el-button :disabled="btnSmsDisabled" plain class="btn-send-code" size="medium" @click="sendSmsCode">
                    {{ btnSmsText }}
                  </el-button>
                </el-form-item>
              </div>
              <div v-if="step===2" class="step2">
                <el-form-item prop="password" label="设置密码">
                  <el-input type="password" v-model="forgetForm.password" placeholder="请输入新密码"></el-input>
                </el-form-item>
                <el-form-item prop="repassword" label="确认密码">
                  <el-input type="password" v-model="forgetForm.repassword" placeholder="请再次输入密码"></el-input>
                </el-form-item>
              </div>
            </div>
            <el-button :disabled="btnNextDisabled" class="btn-next" type="primary" @click="nextStep">下一步</el-button>
          </el-form>
        </div>
      </div>
      <div v-if="step===3">
        <div class="status-main">
          <i class="icon-status el-icon-success"></i>
          <div class="status-info">
            恭喜您，修改成功！
          </div>
          <div class="status-tip">请妥善保管您的账户信息</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getCodeImg, restPwd, sendSmsValidateCode, validateSmsCode} from "@/api/User";
import router from "@/router";

export default {
  name: "ForgetPass",
  data() {
    // 密码的校验方法
    let validatePass = (rule, value, callback) => {
      // 密码以字母开头,长度在6-18之间,允许字母数字和下划线
      const passwordRule = /^[a-zA-Z]\w{5,17}$/;
      if (passwordRule.test(value)) {
        return callback();
      } else {
        return callback(
            new Error("字母开头,长度6-18之间,允许字母数字和下划线")
        );
      }
    };
    // 确认密码的校验方法
    let validateConfirmPass = (rule, value, callback) => {
      // 校验是否以密码一致
      if (this.forgetForm.password != "" && value === this.forgetForm.password) {
        return callback();
      } else {
        return callback(new Error("两次输入的密码不一致"));
      }
    };
    // 校验手机号码
    let validatePhoneNumber = (rule, value, callback) => {
      //校验手机号码格式
      const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (phoneReg.test(value)) {
        return callback();
      } else {
        return callback(new Error("错误的手机号码"));
      }
    };
    return {
      step: 1,
      codeUrl: null,
      title: '找回密码',
      uuid: null,
      forgetForm: {
        phonenumber: null,
        password: null,
        repassword: null,
        code: null,
        imgCode: null
      },
      btnSmsText: "发送短信验证码",
      btnSmsDisabled: false,
      btnNextDisabled: true,
      btnSmsInterval: null,
      btnSmsEnableSecond: 120,
      forgetRules: {
        password: [{required: true, message: "请输入新密码"}, {validator: validatePass}],
        repassword: [{required: true, message: "请输入确认密码"}, {validator: validateConfirmPass}],
        code: [{required: true, message: "请输入短信验证码"}],
        phonenumber: [{required: true, message: "请输入手机号码"}, {validator: validatePhoneNumber}],
        imgCode: [{required: true, message: "请输入图形证码"}]
      }
    }
  },
  computed: {
    ...mapGetters(["getUser"])
  },
  watch: {
    step: function (value) {
      switch (value) {
        case 1:
          this.title = '找回密码'
          break
        case 2:
          this.title = '设置密码'
          break
        default:
          this.title = ''
      }
    },
    // 获取vuex的登录状态
    getUser: function (user) {
      if (user && user.userId) {
        router.push({ path: "/" });
      }
    }
  },
  created() {
    this.getCode()
  },
  methods: {
    sendSmsCode() {
      let flag = true
      //首先校验手机号码是否正确
      this.$refs.forgetForm.validateField("phonenumber", errorMsg => {
        if (errorMsg) {
          flag = false
        }
      })

      if (flag) {//如果手机号码正确，判断用户是否输入图形验证码
        this.$refs.forgetForm.validateField("imgCode", errorMsg => {
          if (!errorMsg) {
            // 发送短信验证码
            sendSmsValidateCode(this.forgetForm.phonenumber, this.uuid, this.forgetForm.imgCode).then(response => {
              if (response.status === 200 && response.data.code === 200) {
                // 弹出通知框提示登录成功信息
                this.notifySucceed('验证码已发送！');

                this.btnNextDisabled = false //启用下一步按钮
                // 120秒禁用发送短信按钮
                this.btnSmsDisabled = true

                this.btnSmsInterval = setInterval(() => {
                  if (this.btnSmsEnableSecond > 0) {
                    this.btnSmsText = this.btnSmsEnableSecond + 's'
                    this.btnSmsEnableSecond--
                  } else {
                    clearInterval(this.btnSmsInterval)
                    this.btnSmsText = '发送短信验证码'
                    this.btnSmsEnableSecond = 120
                    this.btnSmsDisabled = false
                  }
                }, 1000)
              } else {
                this.btnNextDisabled = true //禁用注册按钮
                // 弹出通知框提示登录失败信息
                this.notifyError(response.data.msg);
              }
            }).catch(err => {
              this.btnNextDisabled = true //禁用注册按钮
              return Promise.reject(err)
            })
            this.getCode()
          }
        })
      }
    },
    nextStep() {
      if (this.step === 1) {
        //  验证短信验证码
        let flag = true

        this.$refs.forgetForm.validateField("phonenumber", errorMsg => {
          if (errorMsg) {
            flag = false
          }
        })

        if (flag) {
          this.$refs.forgetForm.validateField("code", errorMsg => {
            if (errorMsg) {
              flag = false
            }
          })
        }

        if (flag) {
          validateSmsCode(this.forgetForm.phonenumber, this.forgetForm.code).then(response => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.step++
              } else {
                this.notifyError(response.data.msg);
              }
            }
          })
        }
      } else if (this.step === 2) {//修改密码
        let flag = true

        this.$refs.forgetForm.validateField("password", errorMsg => {
          if (errorMsg) {
            flag = false
          }
        })

        if (flag) {
          this.$refs.forgetForm.validateField("repassword", errorMsg => {
            if (errorMsg) {
              flag = false
            }
          })
        }
        if (flag) {
          restPwd(this.forgetForm.phonenumber, this.forgetForm.code, this.forgetForm.password).then(response => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.step++
              } else {
                this.notifyError(response.data.msg);
              }
            }
          })
        }
        // this.step++
      } else {
        // console.error("错误的修改密码流程！")
      }

    },
    getCode() {
      getCodeImg().then(res => {
        this.captchaOnOff = res.data.captchaOnOff === undefined ? true : res.data.captchaOnOff;
        if (this.captchaOnOff) {
          this.codeUrl = "data:image/gif;base64," + res.data.img;
          this.uuid = res.data.uuid;
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.btnSmsInterval)
  }
}
</script>

<style scoped>
#forget-pass {
  margin: 0 auto;
  max-width: 1440px;
}

#forget-pass .progress {
  height: 140px;
  background-color: #ffffff;
  margin-top: 32px;
  text-align: center;
  padding-top: 100px;
  font-size: 16px;
}

#forget-pass > .forget-pass-main {
  max-width: 1380px;
  background-color: #ffffff;
  margin-top: 32px;
  padding: 0 30px 0 30px;
  height: 593px;
  text-align: center;
}

#forget-pass .header {
  text-align: left;
  border-bottom: 1px solid #E0E0E0;
}

#forget-pass .el-form {
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center;
}

#forget-pass .el-form .input-items {
  text-align: left;
}

#forget-pass .el-form .btn-next {
  margin-top: 80px;
  width: 246px;
}

#forget-pass .forget-pass-main .body {
  margin: 0 auto;
  width: 700px;
}

#forget-pass .header .title {
  width: 96px;
  border-bottom: 3px solid #1677FF;
}

#forget-pass .header span {
  font-size: 24px;
  font-weight: 600;
  color: #1677FF;
  line-height: 72px;
}

#forget-pass .reg-code {
  width: 23%;
  height: 38px;
  float: right;
}

#forget-pass .reg-code > img {
  cursor: pointer;
  vertical-align: middle;
}

#forget-pass .reg-code-img {
  height: 38px;
  width: 100%
}

#forget-pass .btn-send-code {
  height: 38px;
  width: 23%;
  margin-left: 23px;
}

#forget-pass .icon-status {
  font-size: 64px;
  margin-top: 64px;
}

#forget-pass .icon-status {
  font-size: 64px;
  margin-top: 64px;
}

#forget-pass .el-icon-success {
  color: #1677FF;
}

#forget-pass .status-info {
  margin-top: 25px;
  font-size: 20px;
  font-weight: 600;
  color: #333333;
}

#forget-pass .status-tip {
  color: #B4B4B4;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}

#forget-pass .status-main {
  padding-top: 80px;
}
</style>
